
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Vazirmatn, sans-serif;
}
.vie-inr-cnt .material-icons{margin-right: 5px;}
.crd-info.card-body{padding: 1.5em !important;}
.react-datepicker-wrapper{width:100%;}
.actvty-tble .table tr td{font-weight: 400 !important;}
.crd-info.card-body .col-md-6{padding-left: 0px;}
.crd-info.card-body span{font-size: 18px; color: #00979e;}
.crd-info.card-body .d-flex.mb-4{align-items: center;}
.form-control.search-dash-summary{font-family: Vazirmatn, sans-serif !important;}
.fa.fa-search{opacity: 1;font-size: 14px;}
.form-control{height:100% ;}
body{font-family: Vazirmatn, sans-serif !important;}
.usr-img
{background-color: #ffebd9;border-radius: 15px;padding: 15px;}
.usr-po {
  background-color: #fff9df;
  border-radius: 15px;
  padding: 15px;
}
.lg-main-cnt{display: flex;align-items: center;margin: 0 auto;}
.text-left{
    text-align: left;
  }
.page-title{text-align: left;}
.usr-invs{
  background-color: #fde4ea;
  border-radius: 15px;
  padding: 15px;
}
.usr-pymt {
  background-color: #d9f0f1;
  border-radius: 15px;
  padding: 15px;
}
.usr-info span{font-size: 32px;}
.usr-info p {
  font-size: 14px;margin-bottom: 0rem;color:#757b83 !important;font-weight: 400;
}
#main-logo{position: relative;top:0px;}
.emp-srch .fa.fa-search{left: 22px;
  opacity: 0.5;
  top: 14px;
  font-size: 14px; background: #ffffff;z-index: 2;}
.fa.fa-search.dash.brnch{right: -21px;
  top: 11px;
  font-size: 14px;z-index: 2;opacity: 0.5;}
  .emp-mstr-btn{width:100% !important;height: calc(2.09375rem + 2px) !important;}
.fa.fa-search.dash.emp-mstr{
  position: inherit !important;
  right: -34px;
  top: 14px;
    font-size: 12px;
    margin-right: 10px;
    z-index: 2;
    opacity: 0.5;
  }
  .btn.btn-primary.emp-mstr-btn{background: #00979e !important;color: #ffffff !important;border:#00979e !important;}
  .inf-cnt.btn-srch{float: right;width:100%;color:#00979e !important;}
  .inf-cnt.btn-srch .input-group{justify-content: end;}
.form-control.search-dash-summary.vndr-srh{padding-left: 25px;}
.fa.fa-search.dash.vndr{
  opacity: 0.5;
    right: -21px;
    top: 14px;
    font-size: 14px;
    z-index: 2;
  }
.inf-cnt span{font-size: 20px;}

/* .clr-icn{background: #000000;height:20px;width:20px;border-radius: 50%;padding: 5px;} */
.clr-icn .material-icons{padding: 5px;background: #000000;
  height: 20px;
  width: 20px;
  border-radius: 50%;color: #ffffff;margin-left: 20px;display: flex;
    align-items: center;
    justify-content: center;}
.emp-srch .form-control.search-dash-summary{padding-left:25px;}
.form-control.search-dash-summary.brnch{padding-left: 25px !important;}
.fa.fa-search{right: 30px;top: 10px;}

.w-100{width: 100%;}
select{width:100%;}
@media screen and (min-width:1700px){
.actvty-tble {
    height: calc(100vh - 490px) !important;
    overflow: auto;
  }
    .actvty-crd {
      height: calc(100vh - 490px) !important;
      overflow: auto;
    }

}
.fa.fa-search.dash.ds-brd{color:#abb6bf;position: relative;opacity: 0.5;
  right: -21px;
  z-index: 2;
  top: 14px;}
.form-control.search-dash-summary.ds-brd{padding-left: 25px;}
.main-footer{height:auto !important;}
@media screen and (min-width:1200px) and (max-width:1300px) {
.crd-info.card-body{max-height: calc(100vh - 340px);}
.actvty-tble {
  max-height: calc(100vh - 425px);}
.actvty-crd {
  max-height: calc(100vh - 426px);
  overflow: auto;
}
.stats-small{min-height: 6.7rem !important;}
.pr-order.btn.btn-outline-primary.float-left.porder-button{margin-right: 0px !important;}
}
@media screen and (min-width:1301px) and (max-width:1700px) {
  .screen {
      height: 530px !important;
    }

    .mn-lg.usr-img.d-inline-block.align-top.mr-2.mb-4 {
      margin-bottom: 5px !important;
    }

    .login p {
      margin-bottom: 5px !important;
    }
  .crd-info.card-body {
    max-height: calc(100vh - 340px);
  }

  .actvty-tble {
    max-height: calc(100vh - 450px);
  }

  .actvty-crd {
    max-height: calc(100vh - 450px);
    overflow: auto;
  }

  .stats-small {
    min-height: 6.7rem !important;
  }
}
.crd-info.card-body .col-md-6{padding-right: 0px !important;}
.actvty-tble{height: calc(100vh - 353px); overflow: auto;}
.actvty-crd{height: calc(100vh - 353px); overflow: auto;}
.inf-cnt select{height:40px;width:100%;}
.emp-mstr.card-body .col-12.col-sm-4.col-md-4{padding-left: 0px !important;}
.emp-tble.pymt-sts{height: calc(100vh - 350px); overflow: auto;}
.emp-tble{height: calc(100vh - 318px); overflow: auto;}
.emp-tble.emp-mstr{height: calc(100vh - 315px) !important; overflow: auto;}
.prd-tble {
  height: calc(100vh - 760px);
  overflow: auto;
}
.pro-bar-div{
    height: 1rem !important;
  }
.pro-bar.progress{
    height: 1rem !important;
  }
.progress-bar{height:1rem !important;}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #00979E;
  border-color: #00979E;
  box-shadow: 0 5px 15px rgba(0, 0, 0, .05), 0 4px 10px rgba(0, 123, 255, .25);
}
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show>.btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #00979E !important;
  border-color: #00979E !important;
}
.pts-tble{
    height: calc(100vh - 322px);
    overflow: auto;
  }
.paystatus-down .pagination{justify-content: center;}
.crd-dat{background-color: #fff;padding: 15px;margin: 10px;
  border: none;
  border-radius: 0.625rem;
  box-shadow: 0 0.46875rem 2.1875rem rgba(90,97,105,.1), 0 0.9375rem 1.40625rem rgba(90,97,105,.1), 0 0.25rem 0.53125rem rgba(90,97,105,.12), 0 0.125rem 0.1875rem rgba(90,97,105,.1);}
.error-message {
  color: red;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.error-message.fade-in {
  opacity: 1;
}
.success-message{color: #00979E;}
/* src/Login.css */
.inr.login{padding-top: 5px;}
.bg{position: relative;height:100vh;overflow: hidden;}
.bg{background-image: url('../../images/zoho-bg.svg');background-repeat:no-repeat;background-size: cover;}
.mn-info{display: none;}
.login .mn-lg.usr-img{background: none !important;}
.screen {
  margin: 0 auto;
	background: linear-gradient(90deg, #00979e, #c6e2f0);
	position: relative;
  height: 575px;
	width: 360px;
	box-shadow: 0px 0px 24px #c6e2f0;
}
.form-control.search-dash-summary.emp-srch{border-top: 0;border-left: 0;border-right: 0;}
.screen__content {
	z-index: 1;
	position: relative;
	height: 100%;
}

.screen__background {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 0;
	-webkit-clip-path: inset(0 0 0 0);
	clip-path: inset(0 0 0 0);
}

.screen__background__shape {
	transform: rotate(45deg);
	position: absolute;
}

.screen__background__shape1 {
  height: 630px;
    width: 666px;
    background: #FFF;
    top: -50px;
    right: 55px;
    border-radius: 0 72px 0 0;
}

.screen__background__shape2 {
	height: 220px;
	width: 220px;
	background: #00979e;
	top: -172px;
	right: 0;
	border-radius: 32px;
}

.screen__background__shape3 {
	height: 540px;
	width: 190px;
	background: linear-gradient(270deg, #00979e, #00979e);
	top: -24px;
	right: 0;
	border-radius: 32px;
}

.screen__background__shape4 {
	height: 400px;
	width: 200px;
	background: #00979e;
	top: 420px;
	right: 50px;
	border-radius: 60px;
}
.login {
	width: 100%;
  padding: 15px;
    padding-top: 30px;
}
.login__field {
  display: inline-block;
	padding: 13px 0px;
	position: relative;
}
.login__icon {
	position: absolute;
	top: 30px;
	color: #00979e;
}
.login__field label{font-weight: 500;}
.login__input {
	border: none;
	border-bottom: 1px solid #D1D1D4;
	background: none;
	padding: 10px;
	padding-left: 0px;
	font-weight: 500;
	width: 75%;
	transition: .2s;
}
.login__input:active,
.login__input:focus,
.login__input:hover {
	outline: none;
	border-bottom-color: #00979e;
}
.login__submit {
	background: #fff;
	font-size: 14px;
	margin-top: 0px;
	padding: 16px 20px;
	border-radius: 0px;
	border: 1px solid #D4D3E8;
	text-transform: uppercase;
	font-weight: 700;
	display: flex;
	align-items: center;
	width: 100%;
	color: #00979e;
	box-shadow: 0px 2px 2px #00979e;
	cursor: pointer;
	transition: .2s;
}
.btn.btn-outline-primary.login-btn{border-color: #00979e;background: #fff;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  outline: none;
  color: #00979e;
  margin: 0 auto;
  display: block;}
.form-control.login{box-shadow: 0px 2px 2px #00979e;padding: 16px 20px;}
.btn.btn-outline-primary.login-btn:hover{
  border-color: #00979e !important;
  background: #fff;
    outline: none;
    color: #00979e;
    box-shadow: none;
    position: relative;
    z-index: 1;
}
.btn.btn-outline-primary.login-btn:active {
  border-color: #00979e !important;
  background-color:#ffffff !important;
  outline: none;
  color: #00979e !important;
}
.login__submit:active,
.login__submit:focus,
.login__submit:hover {
	border-color: #00979e;
	outline: none;
}
.otp-login-failed-message{padding-top: 15px;}
.button__icon {
	font-size: 24px;
	margin-left: auto;
	color: #00979e;
}

.social-login {
	position: absolute;
	height: 140px;
	width: 160px;
	text-align: center;
	bottom: 0px;
	right: 0px;
	color: #fff;
}

.social-icons {
	display: flex;
	align-items: center;
	justify-content: center;
}

.social-login__icon {
	padding: 20px 10px;
	color: #fff;
	text-decoration: none;
	text-shadow: 0px 0px 8px #00979e;
}

.social-login__icon:hover {
	transform: scale(1.5);
}
/* .bg::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: -o-linear-gradient(45deg, transparent, #c6e2f0, transparent, #f5f6f8, transparent);
  background: linear-gradient(45deg, transparent, #c6e2f0, transparent, #f5f6f8, transparent);
  background-size: 400% 400%;
  overflow: hidden;
  -webkit-animation: gradient 20s linear infinite;
  animation: gradient 20s linear infinite;
} */

@keyframes gradient {
  0% {
      background-position: 0% 0%;
  }
  100% {
      background-position: 100% 100%;
  }
}

@-webkit-keyframes gradient {
  0% {
      background-position: 0% 0%;
  }
  100% {
      background-position: 100% 100%;
  }
}
.table tr td{
  /* white-space: nowrap; */
  background: #ffffff;
  color: #56688A;
    font-size: 14px !important;font-weight: 400 !important;
    padding: 8px;
    font-weight: 400 !important;
}
.table thead th{
  white-space: nowrap;
  padding: 8px;
background: #f1f5f9 !important;
    color: #56688A !important;
    font-size: 14px !important;
      font-weight: 500 !important;}
.prord-cnt{display: flex;align-items: center;justify-content: space-between;}
.pr-ord-dtls{border:1px solid #c6e2f0;padding: 25px;border-radius: 15px;margin-bottom: 20px;}
.prord-cnt span{display: flex;align-items: center;justify-content: space-between;}
.prord-cnt p{margin-bottom: 0px !important;}
/* width */
::-webkit-scrollbar {
  width: 7px;
  height:7px;
  border-radius: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #dee2e6;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #dee2e6;
}
.page-header .page-title{color: #00979e !important;font-size: 24px !important;}
.main-footer .copyright{text-align: center !important;margin-left: auto;margin-right: auto;padding: 10px !important;}
/* .main-footer.d-flex.bg-white.border-top{padding: 18px !important;} */
.main-sidebar .nav .nav-item .nav-link.active,
.main-sidebar .nav .nav-item .nav-link:hover,
.main-sidebar .nav .nav-item.active,
.main-sidebar .nav .nav-item:hover{box-shadow: inset 0.1875rem 0 0 #00979e !important;
  background-color: #fbfbfb;
  color: #00979e !important;}
.main-sidebar .nav .nav-item .nav-link.active i,
.main-sidebar .nav .nav-item .nav-link:hover i,
.main-sidebar .nav .nav-item.active i,
.main-sidebar .nav .nav-item:hover i{color: #00979e !important;}
.medical-plusicon img{position: absolute;left:175px;background: none !important;width:250px;max-width: 100% !important;}
.medical-plusicon .dmlogonew {position: absolute;bottom: -80px;left: -6px; background: none !important;width: 200px;max-width: 100% !important;}
.login .mn-lg{max-width: 100% !important;width:150px !important;}
.cnt-lnk{color: #00979e !important;text-decoration: underline;}
.form-control{border:1px solid #00979e !important; }
.navbar-search.form-control{border:none !important;}
.fa.fa-search.dash.emp-mstr{color:#ffffff !important;opacity: 1 !important;}
.emp-mstr-dta{color:#00979e !important;display: flex;align-items: center;}
.emp-tble .material-icons{color:#00979e !important;font-size: 20px;position: inherit;}
.vendor-head{position: sticky;top:0px;}
.invoice-head{position: sticky;top:0px;}
.payment-head{position: sticky;top:0px;}
.dash-head{position: sticky;top:0px;}
.vie-cnt{display: flex;align-items: center;color:#00979e !important;border-bottom: 0px !important;}
tr{background: #ffffff !important;}
.emp-mstr-dta span{position: relative;height:10px;width:10px;background: #00979e !important;border-radius: 50%;display: block;margin-right: 5px;}
.nav-link-icon__wrapper .material-icons{color: #00979e !important;}
.emp-mstr-dta{border-bottom: 0px !important;}
.brnch.card-body .emp-tble{height: calc(100vh - 318px);
  }
.vndr-dta.card-body .emp-tble {
  height: calc(100vh - 322px);
}
.vendor-tbody{white-space: nowrap;}
@media screen and (min-width:1701px){
  .pr-order.btn.btn-outline-primary.porder-button.vdr-btn{margin-left: 44%;}
  .pr-order.btn.btn-outline-primary.porder-button.brnch-btn{
      margin-left: 44%;
    }}
@media screen and (max-width:1700px) {
  .pr-order.btn.btn-outline-primary.porder-button.vdr-btn {
    margin-left: 22%;
  }
    .pr-order.btn.btn-outline-primary.porder-button.brnch-btn {
      margin-left: 22%;
    }
}
.react-datepicker-ignore-onclickoutside{height:40px;border: 1px solid #00979e !important;}
.inf-cnt.mr-2{position: relative;}
.form-control.search-dash-summary.py-sts{padding-left: 25px !important;}
.react-datepicker__close-icon{display: none !important;}
.dt-cnt{cursor: pointer;}
  .react-datepicker__input-container input{
      cursor: pointer;
      width:100% !important;
      padding: 8px 15px;
      height: calc(2.09375rem + 2px) !important;
      border: 1px solid #00979e !important;
      border-radius: 0.25rem;
    }

  .aprd span{color: #00979e !important;
  }
  .react-datepicker__input-container{width:100% !important;}
    .react-datepicker__input-container{
        width: 100% !important;
      }
  .form-control.search-dash-summary.pr-ord{padding-left: 25px;}
  .fas.fa-calendar-alt.nw{
    font-size: 16px;
    z-index: 2;}
  .fas.fa-calendar-alt{
    position: relative;
    left:-24px;
    font-size: 16px;
    z-index: 2;}
    .dt-cnt{display: flex;align-items: center;width: 100%;}

.form-control.search-dash-summary.invs{padding-left: 25px;}
.fa.fa-search.dash {
  position: relative;
  right: -21px;
    font-size: 14px;
  z-index: 2;
  top: 14px;
  opacity: 0.5;
}
.invs-tble{height: calc(100vh - 322px); overflow: auto;}
.toggle-sidebar .material-icons{color: #00979e !important;}
@media screen and (max-width:600px) {
   .d-flex {
      display: block !important;
    }
    .d-flex.align-items-center.justify-content-end{display: block !important;}
}
/* .invs-sts{height: calc(100vh - 205px);} */
.rct-cnt{padding-left: 15px !important;}
@media screen and (min-width:1700px){
  .screen{margin-top: 6%;height: 580px;}
}
.prd-dta-tble{height: calc(100vh - 335px) !important;
  overflow: auto;}
@media screen and (min-width:1000px) and (max-width:1700px){
  .prd-tble {
      height: calc(100vh - 260px) !important;
      overflow: auto;
    }
}
@media screen and (min-width:1501px) and (max-width:1600px) {
  .screen {
    height: 510px !important;
  }
}
@media screen and (min-width:1401px) and (max-width:1500px) {
  .screen {
    height: 500px !important;
  }
}
@media screen and (min-width:1301px) and (max-width:1400px) {
  .screen {
      height: 480px !important;
    }
}
@media screen and (min-width:1000px) and (max-width:1300px){

  .screen{height: 460px;}
  .mn-lg.usr-img.d-inline-block.align-top.mr-2.mb-4{margin-bottom: 5px !important;}
  .login p{margin-bottom: 5px !important;}
}
.pr-dtls-pts{
    height: calc(100vh - 250px);
    overflow: auto;
  }
.pr-dtls{height:calc(100vh - 175px);overflow: auto;}
.pr-lft-cnt{cursor: pointer;}
.pr-lft-cnt .material-icons{color:#00979e;font-size: 28px;top:0px !important;}
.pr-lft-cnt .text-sm-left.text-center.text-md-left.mb-sm-0.col-12.col-sm-4 {padding-left: 0px !important;}
.vie-cnt a{display: flex;align-items: center;color:#00979e !important;}
.pr-dtls-rtcnt{border-right: 1px solid #00979e;border-width: 1px !important;}
.pr-dtls-dt p{text-align:center !important;margin-bottom: 5px !important;}
.card-body{padding: 1.2rem !important;}
body{background: #ffffff !important;}
.card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2) !important;}
.main-sidebar{
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2) !important;}
.pr-dtls-rtcnt span{font-weight: 400 !important;}
.pr-dtls-int span {
  font-weight: 400 !important;
}
.dn-ld-ptms-dtls{background: #00979e !important;}
.MuiButtonBase-root {
    background: #00979e !important;
  }
.stats-small__percentage--decrease::before{display: none !important;}
.tab-body.vndact{display: flex;align-items: center;color: #00979e;
  }
.tab-body.act{color: #00979e;}
.dot-ac{height:10px;width:10px;background: #00979e;border-radius: 50%;display: block;
  margin-right: 5px;margin-bottom: 0px;}
.table thead th {
  vertical-align: bottom;
  border: 1px solid #dee2e6 !important;
  border-bottom: 1px solid #dee2e6 !important;
  border-left: 1px solid #dee2e6 !important;
}
.branch-po{
    color: #00979e !important;
    text-decoration: underline;
  }
.branch-inv{
    color: #00979e !important;
    text-decoration: underline;
  }
.branch-pp{
    color: #00979e !important;
    text-decoration: underline;
  }
.act-link{color:#00979e !important;text-decoration: underline;}
.table tr td{border: 1px solid #dee2e6 !important;}
.btn-delete{background: #ffffff;border:none;padding-left: 10px;padding-right: 10px;}
.card-row-content{box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2) !important;padding: 10px;}
.ven-text{font-size: 14px !important;font-weight: 400 !important;margin-bottom: 0px !important;margin-left: 10px;}
.btn-secondary.porder-act{background: none;border:none;display: flex;align-items: center;color:#00979e !important;box-shadow: none !important;}
.btn.btn-viewpayment-details{
    background: none;
    border: none;
    display: flex;
    align-items: center;
    color: #00979e !important;
    box-shadow: none !important;
    font-weight: 500;
  }
.btn-secondary.porder-act:hover {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  color: #00979e !important;
  box-shadow:none !important;
}
.btn-secondary.porder-act:active {
  background: none !important;
  border: none !important;
  display: flex;
  align-items: center;
  color: #00979e !important;
  box-shadow: none !important;
}
.btn-secondary.porder-act:focus {
  background: none !important;
  border: none !important;
  display: flex;
  align-items: center;
  color: #00979e !important;
  box-shadow: none !important;
}
.btn-secondary.porder-act .material-icons{margin-bottom: 5px;margin-right: 5px;}
/*pagination css*/
.pagination {
  margin-left: auto;
  margin-right: 15px;
  display: flex;
  list-style: none;
  outline: none;
}
.pagination>.active>a {
  background-color: #00979E;
  /* border-color: #00979E; */
  color: #ffffff !important;
}
.pagination>li>a {
  /* border: 1px solid #00979E; */
  padding: 5px 15px;
  font-size: 14px;
  /* border-radius: 5px; */
  text-decoration: none;
  outline: none;
  cursor: pointer;
}
.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
  background-color: #00979E;
  /* border-color: #00979E; */
  outline: none;
  color: #ffffff;
}
.pagination>li>a,
.pagination>li>span {
  color: #00979E;
}
.pagination>li:first-child>a,
.pagination>li:first-child>span,
.pagination>li:last-child>a,
.pagination>li:last-child>span {
  border-radius: 0.25rem;;
}
.pagination>li:first-child>a {
  border-radius: unset;
}
.pagination>li:last-child>a {
   border-radius: 0.25rem;;
}
.pagination>.disabled a {
  color: rgb(198, 197, 202);
  /* border: 1px solid rgb(198, 197, 202); */
}
.page-range {
  display: inline-flex;
  margin-left: 20px;
}
.example input[type=text] {
  padding: .4375rem .75rem;
    font-size: 14px;
    border: 1px solid #00979E;
    float: left;
    width: 100%;
    background: #fff;
    height: calc(2.09375rem + 2px);
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
}
.example button {
  float: left;
  width: 25%;
  padding: .4375rem .75rem;
  background: #00979E;
  color: white;
  font-size: 14px;
  border: 1px solid #00979E;
  border-left: none;
  cursor: pointer;
  height: calc(2.09375rem + 2px);
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}
.example button:hover {
  background: #00979E;
}
.example input[type=text]:focus-visible {
  outline: 0;}
.example::after {
  content: "";
  clear: both;
  display: table;
}
.bi.bi-eye-fill{margin-right: 5px;}
/* .pay-act{color:#00979E;text-decoration: underline;} */
.page-title{cursor: pointer;}
.ven-text{color: #56688A;}
.inf-cnt.example{display: flex;}
.view-body{font-size: 14px;}
.invoice-tbody.num-entry{color: #00979E;
  text-decoration: underline;cursor: pointer;}
.login{position: relative;z-index: 1;}
.login-status-msg.fails{
    position: relative;
    z-index: 1;
  }
.d-grid.gap-2.login-btn{
    position: relative;
    z-index: 1;
    margin: 0 auto;
  }
.login-status-msg.fails{opacity: 0;}
.pr-dtls-chk{margin-right: 5px;}
.act-link{text-align: left;}
.vendor-down{display: flex;}
/* .brnch.card-body{height:calc(100vh - 215px);} */
.vendor-down .page-range{margin-left: 0px;}
.vendor-down .pagination{
    margin-right: 0px;
  }
.pr-order.btn.btn-outline-primary{background: rgb(0, 151, 158);display: block;
  border: 1px solid #00979E;
  margin-left: auto;
  margin-right: 15px;
  margin-bottom: 20px;
  color:#ffffff;font-size: 16px;display: flex;
    align-items: center;}
.fa.fa-download{color: #ffffff;
}
.pr-order .material-icons{font-size: 16px;}
.pr-ord-btn:hover{box-shadow: 0 5px 15px rgba(0, 0, 0, .05), 0 4px 10px rgba(0, 123, 255, .25);}
@media screen and (max-width:600px){
  .fl-scren{display: none;}
.porder-down.d-flex{display: block !important;}
}
.nav-item.dropdown{cursor: pointer;}
.email-verify-msg{margin-bottom: 0px;margin-top: 4px;color: red;}
.row.view1{padding-bottom: 10px;}
.pgtle{color: #00979E !important;font-weight: 500;}
.login-message {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  color: red;margin-bottom: 10px !important;
  /* You can adjust the color as needed */
}

.login-message.show {
  opacity: 1;
}
.button.login__submit{margin-top: 10px;}
.wrong-qty-msg{color: red;}
/* .branch-tbody:nth-last-child(1){
  max-width: 100px !important;
}
.branch-tbody:first-child{
  max-width: 80px !important;
} */
.border-right.dropdown.notifications.nav-item{display: flex;}
.fl-scren{background: #ffffff;border:none;}
.d-none.d-md-inline-block{font-weight: 500 !important;}
.react-datepicker__day--in-range,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--selected,
.react-datepicker__month-text--in-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--selected,
.react-datepicker__quarter-text--in-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--selected{background: #ffffff !important;color:#000000 !important;}
.fa.fa-paperclip{color: #00979E;cursor: pointer;}
.approval-tbody-act{color: #00979E;text-decoration: underline;cursor: pointer;}


.alert-message-model.invoice-created-status .modal-content {
  width: 100%;
  border: none;
  padding: 0px !important;
  border-radius: 5px;
  /* background-color: lightseagreen; */
  /* color: white; */
}

.alert-msg-box {
  padding: 5px;
}

.alert-status-msg.fails {
  color: red;
  padding: 5px;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 400;
  margin: 5px;
  align-items: center;
}

.alert-status-msg.success {
  color: green;
  padding: 5px;
  margin: 5px;
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
  align-items: center;
}

.alert-status-msg.success .fa {
  padding-right: 5px;
  padding-left: 5px;
  font-size: 18px;
}


.aprl-cont {
  width: 98% !important;
}

.sea-inv {
  margin: 0 0 0 -60px;
}

.approval-summary {
  margin-top: 30px;
  margin-left: 10px;
}

.invoice-title {
  color: #00979E;
  font-size: 16px;
  margin: 10px 0 0 40px;
  font-weight: 700;
}

.docentry-input-field {
  width: 30% !important;
  margin: 2% !important;
}

.modal {
  background-color: rgba(128, 128, 128, 0.335) !important;
  z-index: 99999;
}

.invoice-select {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: 1px solid #00979E;
  color: #00979E !important;
  height: 40px;
}

.invoice-select:focus {
  box-shadow: none;
  border: 1px solid #00979E;
}

textarea:focus {
  box-shadow: none !important;
  border: 1px solid #888888 !important;
}


.approval-sea {
  position: relative;
  flex: auto;
  width: 40%;
  margin-right: 20px !important;
}

.btn.btn-save-up{background: #00979E;color: #ffffff;}
.btn.btn-save-up:hover {
  background: #00979E;
  color: #ffffff;
}
.btn.btn-cancel{background: #000000;color: #ffffff;}
.btn.btn-cancel:hover {
  background: #000000;
  color: #ffffff;
}
/* /////// DROPDOWN SELECT & SEARCH START ////// */

.seleting-menus {
  display: flex;
  justify-content: flex-end !important;
  align-items: center;
}

.seleting-menus .from-to-date-picker .select {
  margin: 0px !important;
  box-shadow: none;
  border: 1px solid #c2c2c2;
  color: #4e4e4e !important;
  max-width: fit-content;
  font-size: 13px !important;
  height: 30px !important;
  border-radius: 0px !important;
}

.seleting-menus select:focus {
  box-shadow: none;
  border: 1px solid #c2c2c2;
  color: #4e4e4e;
}

.btn-select-search {
  background-color: #00979E;
  margin-left: 5px;
  color: #ffffff;
  box-shadow: none;
  position: relative;
  align-items: center;
  padding: 6px 10px;
  font-size: 12px;
  border-radius: 15px;
}

.btn-select-search:hover {
  color: #ffffff;
  box-shadow: none;
  background-color: #027075;
}

.btn-select-search:focus {
  box-shadow: none;
}

.btn-inline-item {
  align-items: flex-end;
}

.btn-inline-item h6 {
  padding-left: 5px;
  align-items: center;
  margin: 0px;
}

.btn-clear-select {
  background-color: #3f3f3f;
  color: #ffffff;
  align-items: center !important;
  padding: 8px 8px;
  margin-left: 8px;
  font-size: 14px;
  border-radius: 15px;
  line-height: 0;
}

.btn-clear-select:focus {
  box-shadow: none;
  background-color: #3f3f3f;
  color: #ffffff;
}

.btn-clear-select:hover {
  background-color: #070707;
  color: #ffffff;
}

.btn-clear-select .bi-x-circle-fill {
  text-align: center;
  vertical-align: middle;
}

/* /////// DROPDOWN SELECT & SEARCH END ////// */

/* APPROVE-REJECT UPDATE MODEL START */

.main_model {
  background-color: #b8b8b896;
  padding: 10px;
  z-index: 9999;
}

.model-update-leavestatus-body {
  padding: 35px 20px;
}

.update-leavestatus-heading {
  color: #00979E;
  padding: 14px 15px 5px 15px;
  border-bottom: 1px solid #d5d5d5;
  display: flex;
  justify-content: space-between;
}

.input-label-txt {
  font-size: 16px;
  font-weight: 600;
}

.btns-save-cancel {
  padding: 10px 10px 5px 15px;
  border-top: 1px solid #d5d5d5;
  display: flex;
  justify-content: flex-end;
}

.btn-save-up {
  background-color: green;
  width: auto;
  border-radius: 0;
  font-size: 15px;
  color: #ffffff;
  box-shadow: none;
  padding: 5px 15px;
  margin-right: 10px;
}

.btn-save-up span {
  font-size: 14px;
  font-weight: 400;
  position: relative;
}

.btn-save-up:hover {
  color: #ffffff;
}


.btn-cancel {
  background-color: black;
  width: auto;
  border-radius: 0;
  font-size: 15px;
  color: #ffffff;
  box-shadow: none;
  padding: 5px 15px;
  margin-right: 10px;
}

.btn-cancel span {
  font-size: 14px;
  font-weight: 400;
  position: relative;
}

.btn-cancel:hover {
  background-color: gray;
  color: rgb(255, 255, 255);
  border: 1px solid gray;
}

.approval-tbody-act {
  color: #00979E;
  cursor: pointer;
}

.approval-tbody-act:hover {
  text-decoration: underline;
}

.approval-tbody.pending {
  color: orange;
}

.approval-tbody.approve {
  color: green;
}

.approval-tbody.rejected {
  color: red;
}

.approve-status {
  font-size: 13px;
  color: #00979E;
  padding: 3px;
}

.approve-status:hover {
  color: #00979E;
}

.approve-status i {
  font-size: 13px;
  color: #00979E;
  margin-right: 5px;
}

.btn.approve-status.hide {
  display: none;
  visibility: hidden;
}

.btn.approve-status.hide.ok {
  color: blue;
}

.select-leave-update-status {
  border-radius: 5px;
  font-size: 13px;
  /* background-color: gray; */
}

.update-leave-status .btn,
.update-leave-status .form-control {
  box-shadow: none;
}

.update-leave-status .form-control:focus {
  border: 1px solid #b1b1b1;
}

.update-leave-status .select-status {
  justify-content: space-between;
  align-items: center;
  margin: 10px 10px;
}

/* APPROVE-REJECT UPDATE MODEL END */



.approval-table>:not(:first-child) {
  border-top: none !important;
}

.approval-head {
  background-color: #F5FBFB;
  position: sticky;
  top: 0;
}

.approval-thead,
.sta-head {
  color: #888888;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.02rem;
}

.input-approve-checkbox {
  margin: 0px 5px 0px 0px;
}

.aplsta-act {
  color: #00979E !important;
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
}

.approval-tbody {
  color: #2E2E2E;
  font-size: 14px;
}


.approval-tbody {
  color: #2E2E2E;
  font-size: 14px;
  font-weight: 400;
  padding: 5px 5px !important;
}

.approval-tbody .fa-paperclip {
  color: #00979E;
  font-size: 14px;
  font-weight: 600;
}

.btn.approve-status.hide {
  display: none;
}

.edit-link,
.view-link {
  color: #00979E !important;
  cursor: pointer;
  font-size: 14px;
}

/* Upload Button Here */
.file {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file>input[type='file'] {
  display: none
}

.file>label {
  font-size: 1rem;
  font-weight: 300;
  cursor: pointer;
  outline: 0;
  user-select: none;
  border-color: rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186);
  border-style: solid;
  border-radius: 4px;
  border-width: 1px;
  background-color: hsl(0, 0%, 100%);
  color: hsl(0, 0%, 29%);
  padding-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2% 36%;
  color: #fff !important;
  border-color: #00979E !important;
  background-color: #00979E !important;
}

.submit-invoice {
  border: none !important;
  font-size: 13px !important;
  background-color: #00979E !important;
  padding: 10px 15px;
  margin: 15px 0px;
  float: right;
  color:#ffffff;
}

.uppdf {
  margin-right: 15px;
}

#remarks-update-invoice {
  font-size: 14px;
}

.remarks-field .remark-text {
  color: red;
}

/* Upload Button Here */
.edit-img,
.view-img {
  border: none;
}

.dot-sta {
  background-color: rgba(204, 51, 0, 0.15);
  border-radius: 15px;
  color: #ED4F35;
  font-size: 14px;
  padding: 6px 12px;
}

.dot-sta-open {
  background-color: rgba(0, 151, 158, 0.15);
  border-radius: 15px;
  color: #00979E;
  font-size: 14px;
  padding: 5px 25px;
}

.edit-img {
  margin: -3px 0 0 0 !important;
}

.ver-dots {
  border: 1px solid #00979E;
  border-radius: 5px;
  color: #00979E;
  margin: 0 0 0 5px;
}

.edit-modal,
.load-modal,
.view-modal {
  width: 95% !important;
  height: auto !important;
  margin-top: -5%;
}

.payment-model {
  background-color: #b2aeae8c;
  z-index: 99999;
}

.payment-model-head {
  background-color: #effafa;
  border-radius: 20px !important;
  text-align: center;
  padding: 5px;
  color: #00979E;
  font-size: 20px;
}

.pay-head {
  color: #00979E;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.inv-input {
  height: 30px !important;
  margin-bottom: 5px;
}

.input-model-label {
  margin-top: 0px !important;
  font-size: 13px;
}

.inv-num {
  color: #00979E;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.my-input {
  margin-top: 15px;
}

.my-text {
  margin-top: 10px;
}


.progress-bar {
  background-color: #3db3b9;
}

.save-up {
  color: #00979E !important;
  border-color: #00979E !important;
  background-color: #fff !important;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 15px;
  letter-spacing: 1px;
}

.save-up:hover {
  color: #fff !important;
  border-color: #00979E !important;
  background-color: #00979E !important;
}

.mydrop {
  color: #6c757d !important;
  background-color: #fff !important;
  border-color: #fff !important;
  margin: -45px 0 0 145px;
}

.mydrop:after {
  display: none !important;
}

.menu-action {
  transform: translate(-25px, 3px) !important;
  border: none;
  box-shadow: 0px 0px 8px rgb(0 0 0 / 10%);
}

.mytextarea {
  margin-top: 15px;
}

.save-doc {
  text-align: right;
  color: #00979E !important;
  border-color: #00979E !important;
  margin-top: 5px;
  padding: 3px 15px;
}

.save-doc:hover {
  color: #fff !important;
  border-color: #00979E !important;
  background-color: #00979E !important;
  margin-top: 5px;
  padding: 3px 15px;
}

.inv-payment,
.doc-no,
.due-date,
.pay-date,
.my-amount,
.myref,
.mypay,
.youref,
.yourpay,
.naration {
  font-size: 12px;
}


.myinvoive-head {
  text-align: center;
  color: #00979E;
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 10px;
}

/* .myvoice {
    margin-top: 5px;
} */

.excel-down {
  color: #00979E !important;
  border-color: #00979E !important;
  padding: 2px 10px !important;
  border-radius: 5px !important;
  box-shadow: none !important;
  margin-right: 10px;
}

.download-dropdown {
  min-width: 140px;
  background-color: #f8faff;
  border: none;
  border-radius: 15px;
}

.excel-down:hover {
  color: #fff !important;
  border-color: #00979E !important;
  background-color: #00979E !important;
  padding: 2px 10px;
  border-radius: 5px;
  box-shadow: none;
}

/* .pdf {
    /* width: 80%; */
/* margin: 10%; */
/* background-color: #ffffff; */

/* margin: 8% 0 0 4%; */
/* } */

/* .ok-pdf {
    color: #00979E !important;
    border-color: #00979E !important;
    font-size: 12px;
    font-weight: 600;
    margin: 20px 0;
    padding: 3px 20px !important;
}

.ok-pdf:hover {
    color: #fff !important;
    border-color: #00979E !important;
    background-color: #00979E !important;
    padding: 3px 20px !important;
} */

.btn-primary {
  color: #00979E !important;
  border-color: #00979E !important;
  background-color: #ffff;
}

.btn-primary.approval-button {
  align-items: center;
  color: #00979E !important;
  border-color: #00979E !important;
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 10px;
  line-height: 1.2;
}

.btn-primary.approval-button:hover {
  color: #fff !important;
  border-color: #00979E !important;
  background-color: #00979E !important;
}

.btn-primary.approval-button:focus {
  box-shadow: none;
}


.approval-down {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.approval-scroll {
  position: relative;
  overflow: auto;
  height: auto;
}



/* Very Small devices (landscape phones, 576px and down) */
@media (max-width: 576px) {
  .invoice-title {
    margin: 0 0 5px 0;
  }

  .invoice-select {
    margin: 5px 0;
  }

  .approval-input {
    margin: 8px 0 0 24px;
  }

  .invoice-right {
    margin: 20px 0 0 10px;
  }

  .approval-summary {
    margin: 30px 0 0 -4%;
    width: 113%;
  }

  .approval-scroll {
    margin: 0 0 0 1%;
  }

  .ver-dots {
    margin-left: -30px;
  }

  .menu-action {
    transform: translate(10px, 3px) !important;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .approval-scroll {
    margin: 0 0 0 25px !important;
    width: 98% !important;
  }

  .menu-action {
    transform: translate(15px, 3px) !important;
  }
}

/*  Large devices (desktops, 992px and up) */
@media (min-width: 992px) {}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width:1399px) {
  .menu-action {
    transform: translate(-15%, 3px) !important;
  }
}

/* emplyee css */


.emp-content{
  width: 98%;
  position: relative;
}
.employee-summary {
  margin-top: 20px;
  margin-left: 10px;
}

.fa-search:focus{
  color: #00979E;
}
.page-title-box{
  display: flex !important;
}

.act {
  color: #00979E !important;
}

.dot-ac {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  background-color: #00979E;
}

.seleting-menus{
  display: flex;
  justify-content: flex-end !important;
  align-items: center;
}
.page-table-title{
  flex: auto;
  font-weight: 500;
  color: #00979E;
  margin: 1% 0px 1% 0px !important;
}

.seleting-menus .select{
  margin: 5px 5px !important;
  box-shadow: none;
  border: 1px solid #c2c2c2;
  color: #4e4e4e !important;
  max-width: fit-content;
  font-size: 13px !important;
  height: 30px !important;
  border-radius: 10px !important;
}
/* .seleting-menus .select:focus {
  box-shadow: none;
  border: 1px solid #838383;
  color: #d21010;
} */
/* .select .option-label {
  color :#027075;
  background-color: #f5fbfc;
} */

.employee-summary .btn-select-search {
  background-color: #00979E;
  margin-left: 5px;
  color: #ffffff;
  box-shadow: none;
  position: relative;
  align-items: center;
  padding: 6px 10px;
  font-size: 12px;
  border-radius: 15px;
}
.btn-select-search:hover {
  color: #ffffff;
  box-shadow: none;
  background-color: #027075;
}
.btn-select-search:focus {
  box-shadow: none;
}
.btn-inline-item{
  align-items: flex-end;
}
.btn-inline-item h6{
  padding-left: 5px;
  align-items: center;
  margin: 0px;
  line-height: 1.1;
  font-size: 15px;
}
.employee-summary .btn-clear-select{
  background-color: #3f3f3f;
  color: #ffffff;
  align-items: center !important;
  padding: 8px 8px;
  margin-left: 8px;
  font-size: 14px;
  border-radius: 15px;
  line-height: 0;
}
.btn-clear-select:focus {
  box-shadow: none;
  background-color: #3f3f3f;
  color: #ffffff;
}
.btn-clear-select:hover {
  background-color: #070707;
  color: #ffffff;
}

.btn-clear-select .bi-x-circle-fill{
  text-align: center;
  vertical-align: middle;
}

.employee-down {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.alert-message-model .modal-content{
  width: 100%;
  border: none;
  padding: 0px !important;
  border-radius: 10px;
}

.delete-employee-title{
  background-color: #04a1a9;
  color: #ffffff;
  justify-content: space-between;
  padding: 5px 20px;
  margin: 0px 0px;
  display: flex;
  align-items: center;
}
.delete-employee-title h6{
  margin-bottom: 0px;
}
.delete-employee-title .bi-trash-fill {
  font-size: 18px;
  padding: 8px 0px 4px 5px;
}

.alert-status-msg-delete{
  font-size: 16px;
  margin: 15px 10px;
}
.alert-status-msg-delete .delete-success-msg{
  color: green;
}
.delete-ok-cancel{
  float: right;
  margin: 10px 20px;
  justify-content: space-between;
}
.delete-ok-cancel .btn-ok{
  background: rgb(0, 151, 158);
  border: none;
  color: rgb(255, 255, 255);
  padding: 0.4375rem 0.75rem !important;
  border-radius: 5px;
  margin: 5px;
}
.delete-ok-cancel .btn-ok:hover{
  background: rgb(0, 151, 158);
  border: none;
  color: rgb(255, 255, 255);
  padding: 0.4375rem 0.75rem !important;
  border-radius: 5px;
  margin: 5px;
}
.bi.bi-trash-fill{opacity: 0 !important;}
.delete-head-text{color: #ffffff !important;}
.delete-ok-cancel .btn-cancel-model {
  background-color: black;
  color: white;
  padding: 0.4375rem 0.75rem !important;
  border-radius: 5px;
}
.delete-ok-cancel .btn-cancel-model:hover {
  background-color: black;
  color: white;
  padding: 0.4375rem 0.75rem !important;
  border-radius: 5px;
}

.page-range>p{
  margin-bottom: 0px;
}
/* INPUT FIELDS START */
/* .employee-select {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: 1px solid #00979E;
  color: #00979E !important;
  height: 30px;
  width: 100px;
  margin-left: 10px;
}
.employee-select:focus{
  box-shadow: none;
  border: 1px solid #00979E;
} */
.emp-input{
  height: 40px;
  padding: 0px;
  box-shadow: 0px 2px 4px -4px;
}

.employee-input:focus {
  box-shadow: none !important;
  outline: none !important;
}
.input-group>.form-control:focus,
.input-group>.form-select:focus {
  z-index: 0 !important;
}
/* INPUT FIELDS END */

.employee-sea {
  position: relative;
  /* left: 145px; */
  /* bottom: 30px;        */
}
.employee-head {
  background-color: #F5FBFB;
  position: sticky;
  top: 0;
  border-radius: 20px !important;
}

.employee-act {
  color: #00979E;
}

.emp-card {
  border: none;
  box-shadow: 0px 0px 8px rgb(0 0 0 / 10%);
  border-radius: 5px;
}


.tab-head {
  color: #888888;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.02rem;
}

.employee{
margin-top: 20px;
}
.employee-scroll {
  position: relative;
  height: 450px;
  overflow: auto;
  padding: 0;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
  border: none;
  box-shadow: 0px 0px 8px rgb(0 0 0 / 10%);
  border-radius: 5px;
}

/* width */
.employee-scroll::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {

  border-radius: 10px;
}

.employee-table>:not(:first-child) {
  border-top: none !important;
}

.employee-img {
  border: none;
}

.employee-button {
  align-items: center;
  color: #00979E !important;
  border-color: #00979E !important;
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 10px;
  line-height: 1.2;
}

.employee-button:hover {
  color: #fff !important;
  border-color: #00979E !important;
  background-color: #00979E !important;
}
.employee-button:focus {
  box-shadow: none;
}

.tab-body .btn-delete .bi {
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
}
.model-title-delete{
  align-items: center;
}


/* Very Small devices (landscape phones, 576px and down) */

@media (max-width:320px) {
  .employee-scroll {
      margin: 0 0 0 20px;
      width: 85%;
  }

  .emp-input {
      height: 40px;
      margin: 10px 0 0 25px;
  }
}

@media (min-width:321px) and (max-width:576px) {
  .employee-scroll {
      margin: 0 0 0 6%;
      width: 88%;
  }

  .emp-content {
      width: 100% !important;
      position: relative;
      margin: 0 0 0 2% !important;
  }

  .employee-title {
      margin: 0;
  }

  .employee-summary {
      margin: 30px 0 0 10px;
  }

  .employee-select {
      margin: 5px 0;
  }

  .emp-input {
      height: 40px;
      margin: 8px 0 0 24px;
  }
}

/* Small devices (landscape phones, 576px and up) */


/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .employee-scroll {
      margin: 0 0 0 26px !important;
      width: 98% !important;
  }
}

/*  Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width:1199px) {
  .emp-content {
      width: 105%;
      margin: 0 0 0 -5%;
  }
}




